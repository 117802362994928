.content-header{
    .header{
        img{
            width: 100%;
            height: 100%;
        }
        .imgPc{
            display: block;
        }
        .imgMobile{
            display: none;
        }
    }
    
    .logo{
        background-color: #1b1b1b;
        text-align: center;
        padding: 10px;
        cursor: pointer;
        .menu{
            color: #fff;
            font-size: 35px;
            display: none;
            i{
                cursor: pointer;
                vertical-align: middle;
            }
        }
    }
    
    @media screen and (max-width: 767px) {
        .header{
            .imgPc{
                display: none;
            }
            .imgMobile{
                display: block;
            }
        }  
    
        .logo{
            display: flex;
            justify-content: space-between;
            .menu{
                display: block;
            }
        }
    }
    
    .download-app{
        position: fixed;
        bottom: 170px;
        right: 0;
        z-index: 3;
        display: none;
        img{
            margin-bottom: 5px;
            border-radius: 5px; 
        }
    }
    
}

.modal-buy-shine{
    .ant-modal-header{
        .ant-modal-title{
            font-size: 24px;
            font-family: helvetica neue,Helvetica,Arial,sans-serif;
            font-weight: bold;
            text-align: center;
            .text{
                font-size: 18px;
            }
        }
    }
    .ant-modal-body{
        img{ width: 32px}
        text-align: center;
        .ic-button{
            height: 45px;
            width: auto;
            margin-top: 20px;
        }
    }
}


@media screen and (max-width: 767px){
    .modal-buy-shine{
        .ant-modal-header{
            .ant-modal-title{
                font-size: 17px;
                .text{font-size: 14px;}
            }
        }

        .ant-modal-body{
            img{ width: 25px}
            .ic-button{
                height: 35px;
            }
        }
    }
}