.hair-hot{
    background-color: #fff;
    margin-bottom: 4px;
    padding: 10px 15px 0px;
    .header{
        padding-bottom: 10px;
    }
    .hair-iamge{
        display: flex;

    }
    .item{
        display: inline-block;
        width: 25%;
        padding: 5px 24px;
        position: relative;
    
        .image{
           width: 100%;
           border: 3px solid #333;
           height: 264px;
           padding: 4px;
           border: 4px solid #e6e6e6;
           object-fit: cover;
        }
    
        .logo-hair-hot{
          position: absolute;
          width: 50px;
          top: 6%;  
          right: 17%;
        }
    
        .like{
            font-family: Arial;
            font-weight: 700;
            font-size: 15px;
            max-width: 200px;
            color: #000;
        }
        .icon{
            font-size: 18px;
            margin-right: 20%;
        }
    }
    .add-show{
        display: grid;
        font-size: 14px;
        text-align: center;
        color: #565656;
      }
}
@media screen and (max-width: 767px) {
    .hair-hot{
        padding: 10px 5px;
        .header{
            padding-bottom: 5px;
            padding-left: 5px;
        }
        .header{
            img{
                width: 100%;
            }
        }
        .item{
            padding: 3px;
            .image{
                height: 132px;
                padding: 1px;
            }
            .like{
                font-size: 12px;
            }
            .icon{      
                margin-right: 2px;
            }
            .logo-hair-hot{
                width: 30px;
                top: 5%;
                right: 10%;
            }
        }
    }
    
}