
.modal-checkbooking{
    .ant-modal-title{
        font-size: 20px; 
    }
    .text{
        margin: 10px 0;
        
        font-size: 20px;
        text-align: center;
        color: #1b1b1b;
        line-height: 30px;
        .link-booking{
            padding: 4px;
            background: #ffd800;
            color: #1b1b1b;
            font-weight: bold;
        }
        .default{
            font-size: 18px;
            font-weight: 400;
        }
        .cus_phone{
            font-weight: normal;
            text-transform: capitalize;
        }
        .name{
            text-transform: capitalize;
        }
    }
    .box{
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        input{
            width: 70%;
            font-size: 25px;
            
        }
        .action-check{
            width: 28%;
            background: #1b1b1b;
            color: #fff;
            margin-left: 2%;
            padding: 5px;
            font-weight: bold;
            text-align: center;
            cursor: pointer;
            height: 40px;
        }
    }
    .images{
        display: flex;
        justify-content: center;
        img{
            cursor: pointer;
        }
    }
    .action{
        padding: 5px;
        display: flex;
        justify-content: center;
        a, div{
            color: #1b1b1b;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 2px 12px;
            cursor: pointer;
        }
        div{
            margin-left: 10px;
        }
    }
}

@media screen and (max-width: 767px){
    .modal-checkbooking{
        .text{
            font-size: 16px;
            .default{
                font-size: 16px;
            }
        }
        .box{
            input{
                font-size: 20px;
            }
        }
    }
}