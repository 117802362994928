.menu-service {
	background: #fff;
	margin-bottom: 4px;
	.price-menu {
		width: 100%;
		.item {
			text-align: center;
			padding: 10px 0;
			height: auto;
			a {
				display: block;
				color: #000;
				height: 100%;
				display: flex;
				flex-flow: column;
			}
			.title {
				padding: 3px 0;
				font-size: 16px;
			}
			.price {
				font-size: 18px;
				font-weight: bold;
				margin-top: auto;
			}
			img {
				cursor: pointer;
				width: 100%;
				padding: 0 15px;
			}
		}
	}
	.container-header {
		padding: 10px;
		font-weight: bold;
		// font-size: 22px;
	}
}
@media screen and (max-width: 767px) {
	.menu-service {
		.price-menu {
			.item {
				.title {
					font-size: 3.2vw;
				}
				.price {
					font-size: 3.6vw;
				}
			}
		}
	}
}
