.fashion-30shine{
    padding: 0 10px;
    background: #fff;
    margin-top: 4px;

    .header{
        padding: 10px;
        padding-left: 0;
    }

    .item{
        text-align: center;
        .text{
            padding: 15px;
            span{
                font-size: 14px;
            }
        }
    }
}
