
.navbar{
    justify-content: center;
    padding: 0;
    background: #fff;
    position: relative;
    .navbar-nav{
        flex-direction: row;
        .icon-left{
            display: flex;
            align-items: center;
            img{
                width: 25px;
                cursor: pointer;
            }
        }
        .icon-right{
            position: absolute;
            cursor: pointer;
            top: 50%;
            right: 5%;
            padding: 5px 10px;
            transform: translateY(-50%);
            .image-locale{
                img{
                    margin-bottom: 5px;
                    width: 30px;
                }
                span{
                    font-size: 15px;
                    vertical-align: middle;
                    font-weight: bold;
                }
            }
        }
        .nav-item{
            font-size: 24px;
        }
    }
    .icon-close{
        background-color: #353535;
        height: 50px;
        color: #fff;    
        font-size: 29px;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        cursor: pointer;
        i{
            line-height: 2;
        }
         display: none;
    }
    .icon-menu{
        display: none;
    }

    .dropdown-menu{
        width: 100%;
        .dropdown-item{
          height: 43px;
          padding-left: 90px;
          &:hover{
              color: #024ddc;;
          }
        }
    }
}



.nav-link{
    font-size: 20px;
    font-weight: bold;
    color: #000;
    padding-left: 16px !important;
    padding-right: 0 !important;
    &:active{
        color: #1b1b1b;
    }
    &:hover{
        color: #1b1b1b;
    }
}



@media screen and (max-width: 990px) {
    .navbar-nav{
        .store, .recruitment{
            display: none;
        }
    }
}


@media screen and (max-width: 767px) {
    .navbar{
        position: fixed;
        z-index: 999;
        top: 0;
        width: 70%;
        right: 0;
        height: 100%;
        transform: translateX(100%);
        transition: transform 0.3s ease-out;
        .navbar-nav{
            display: block;
            background-color: #fff;    
            // width: 290px;
            height: 100%;
            overflow: auto;     
            padding: 0;
            box-shadow: 2px 2px 2px #ccc;
            .nav-item {
                border-bottom: 1px solid #ccc;
                padding-left: 20px;
                .nav-link{
                    font-size: 4.4vw;
                    i{
                        font-size: 4.4vw;
                    }
                }
                .nav-locale{
                    display: flex;
                    font-size: 4.4vw;
                    color: #000;
                    padding: .5rem 16px;
                    align-items: center;
                    font-weight: bold; 
                    text-transform: uppercase;
                    font-stretch: condensed;
                    img{
                        width: 30px;
                        margin-left: 15px;
                        cursor: pointer;
                    }
                    .image-locale{
                        span{
                            vertical-align: middle;
                        }
                    }
                }
            }

            .store,.recruitment{
                display: block;
            }

            .icon-close{
               display: flex;
            }

            .icon-menu{
                display: inline;
            }
            
        }
    }

    .navbar.open{
        transform: translateX(0);
    }
    .logo{
        height: 81px;
        display: flex;
        padding: 10px 30px 10px;
        img{
            width: 100px;
        }
    }
    
}



