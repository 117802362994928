


.dropdown-location{
   width: 100%;
   .btn-group{
       width: 100%;
       ul{
           width: 100%;
           padding: 0;
           li{
            font-size: 20px;
              
            cursor: pointer;
            &:hover {
               background-color: rgb(63, 63, 63);
               color: #fff;
            }       
           }
       }
   }
   .list-data{
       width: calc(100% - 1px);
       border: 1px solid #ccc; 
       max-height: 330px;
       overflow: auto;
       &::-webkit-scrollbar {
        width: 7px;
        background-color: #ffd800;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #3f3602;
        }
       .item{
           width: 100%;
           padding: 10px 0 10px 10px;
           display: flex;
           .address{
               width: 45%;
               
               font-size: 15px;
               display: flex;
               font-weight: 700;
               .key{
                   font-size: 20px;
                   margin-right: 5px;
                   width: 20px;
                   
               }
               .name{
                   width: calc(100% - 20px) ;
               }
           }
           .phone{
              margin-left: 2%;
              width: 53%;
              display: flex;
             .box{
                 font-size: 20px;
                 width: 90%;
                 background: url('https://v3.30shine.org/data/images/Trangchu/btn-phone-yellow.png') no-repeat;
                 background-size: 100% auto;        
                 span{
                     line-height: 41px; 
                     margin-left: 25%;
                 }
             }
             img{
                width: 35px;
                height: 35px;
             }
             
           }
       }
   }
}

@media screen and (max-width: 767px) {
    .dropdown-location{
        .list-data{
            .item{
                .address{
                    font-size: 3.7vw;
                }
                .phone{
                    .box{
                        width: 92%;
                        font-size: 18px;         
                    }
                    img{
                        width: 28px;
                        height: 28px;
                    }
                }
            }
        }
    }
}
