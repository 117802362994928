.modal-booking{
    top: 30%;
    .ant-modal-header, .ant-modal-close{
        display: none;
    }
    .ant-modal-content{
        border-radius: 0; 
        .ant-modal-body{
            padding: 0;
        }
    }
}