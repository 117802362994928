.my-swiper{
    position: relative;
    .btn-action{
        color: #ccc;
        font-size: 50px;
        position: absolute;
        top: 30%;
        cursor: pointer;
    }
    .left{
       left: -55px;
    }
    .right{
        right: -55px;
     }
}