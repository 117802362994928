// @font-face{
//       font-family: Roboto;
//       font-display: auto;
//       src: url('https://s3-ap-southeast-1.amazonaws.com/std.30shine.co.th/data/font/Roboto-Regular.ttf') format('woff2');
     
// }

// @font-face{
//       font-family: HelveticaVn-BlkCn;
//       font-display: auto;
//       src: url('https://s3-ap-southeast-1.amazonaws.com/30shine-website-resources/font/HelveticaVn-BlkCn.ttf') format('woff2');
// }
  
// @font-face{
//       
//       font-display: auto;
//       src: url('https://s3-ap-southeast-1.amazonaws.com/30shine-website-resources/font/HelveticaVn-LtCn.ttf') format('woff2');
// }

@font-face{
      font-family: Open-Sans;
      font-display: auto;
      src: url('https://s3-ap-southeast-1.amazonaws.com/30shine-website-resources/font/kid/OpenSans-Regular.ttf') format('woff2');
  }
  

@font-face{
      font-family: FuturaBk-Bold;
      font-display: auto;
      src: url('https://s3-ap-southeast-1.amazonaws.com/30shine-website-resources/font/FuturaBk-Bold.TTF') format('woff2');
}

@font-face{
      font-family: iCielAltusSerif;
      font-display: auto;
      src: url('https://s3-ap-southeast-1.amazonaws.com/v3.30shine.org/data/font/iCielAltusSerif.otf') format('woff2');
}

.App {
      font-family: Arial, sans-serif, Helvetica ;
      background-color: #eee;
      color: #1b1b1b;
      font-size: 14px;
      .back-drop{
            display: none;
      }
      .back-drop.open{
            display: block;
      }
     
      .btn-group{
            position: relative;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            vertical-align: middle;
      }
      .dropdown-menu.show{
            display: block;
      }
      .nav-link{
            display: block;
            padding: .5rem 1rem;
            text-transform: uppercase;
            font-stretch: condensed;
      }
      .affix{
            font-stretch: condensed;
      }
}
.text-center{
      text-align: center;
}
.ant-message{
      top: 9px;
      .ant-message-notice{
            padding:  0;
            .ant-message-notice-content{
                  border-radius: 0;
                  border: 1px solid #ccc;
                  background-color: #f3afb3;
                  width: 100%;
                  max-width: 500px;
                  text-align: left;
                  opacity: 0.9;
                  .ant-message-custom-content{
                        font-size:  20px;
                        
                        color: #1b1b1b;
                        i{
                              width: 15px;
                              font-size:  20px;
                              top: 0;
                              width: 20px;
                        }
                  }
            }  
      }   
  }
   
  .dropdown-custom{
      width: 100%;
      position: relative;
      .btn-custom{
          text-transform: uppercase;
          width: 100%;
          border: none;
          font-size: 20px;
          font-weight: bold;
          border-radius: 0;
          background: #ffd800 ;
          box-shadow: none ;
          padding: 8px 5px;
          text-align: center;
          font-stretch: condensed;
          cursor: pointer;
          &::after{
              display: inline-block;
              width: 0;
              height: 0;
              margin-left: .255em;
              vertical-align: 0;
              content: "";
              border-top: .3em solid;
              border-right: .3em solid transparent;
              border-bottom: 0;
              border-left: .3em solid transparent;
              font-size: 45px;
          }
      }  
      ul{
            width: 100%;
            padding: 0;
            li{
             font-size: 25px;
               
             border-bottom: 1px solid #ccc; 
             text-transform: uppercase;
             cursor: pointer;
             &:hover {
                background-color: rgb(63, 63, 63);
                color: #fff;
             }       
            }
        }
}

  .dropdown-menu{
      position: absolute;
      top: 100%;
      left: 0px;
      will-change: transform;  
      position: absolute;
      z-index: 1000;
      min-width: 10rem;
      padding: .5rem 0;
      margin: .125rem 0 0;
      font-size: 1rem;
      color: #1b1b1b;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0,0,0,.15);
      .dropdown-item{
            display: block;
            width: 100%;
            padding: .25rem 1.5rem;
            clear: both;
            font-weight: 400;
            color: #212529;
            text-align: inherit;
            white-space: nowrap;
            background-color: transparent;
            border: 0;
            // font-stretch: condensed;
      }
}


@media screen and (min-width: 767px){
      .App{
            .navbar{
                  position: relative;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  -webkit-box-align: center;
                  .navbar-nav{
                        padding-left: 0;
                        margin-bottom: 0;
                        list-style: none;
                        display: flex;
                        -webkit-box-orient: vertical;
                        -webkit-box-direction: normal;
                  }
            }

      }
      .ant-notification{
            margin-left: 50% !important;
            transform: translateX(-50%);
      }
}

.ant-notification{
      opacity: 0.9;
      width: 100% !important;
      max-width: 500px !important;
      left: 0;
      top: 0 !important;
      .ant-notification-notice{
            border-radius: 0;
            padding: 12px;
            .ant-notification-notice-content{
                  color: #000;
                  font-family: sans-serif;  
                  .ant-notification-notice-message,.ant-notification-notice-description{
                     font-size: 18px;
                     margin-left: 40px;
                  }
                  .ant-notification-notice-close{
                        right: 15px;
                  }
            }
      }
      .error{
            background: #f3afb3;
      }
      .success{
            background: rgba(166,239,184,.9);
      }
}





@media screen and (max-width: 768px){
      .dropdown-custom{
            .btn-custom{
                 font-size: 6.1vw; 
            }
      }
      .ant-notification{
            .ant-notification-notice{
                  .ant-notification-notice-content{
                        .ant-notification-notice-message,.ant-notification-notice-description{
                              font-size: 4.5vw;
                        }
                  }
            }
      }
}
