
.home{
  .container{
    max-width: 1024px;
    padding: 0;
    margin: auto;
    background-color: #eee;
  
    .add-show{
      display: grid;
      font-size: 14px;
      text-align: center;
      color: #565656;
    }
  }
}



@media screen and (max-width: 1024px) {
   .container{
     width: 100%;
   }
}

