
.input-sologan{
    position: absolute;
    max-width: 560px;
    bottom: 2%;   
    left: 0;
    right: 0;
    z-index: 2;
    margin: 0 auto;
    font-stretch: condensed;
    .input-phone{
        background-color: #fff;
        // border: 1px solid #ccc;
        padding: 10px;
        .input-text{
            display: flex;
            .icon{
                display: flex;
                img{
                    width: 40px;
                    height: 40px;
                }
                .right{
                    width: 1px;
                    height: 40px;
                    margin-left: 5px;
                }
            }
            input{
                color: #1b1b1b;
                font-size: 22px;    
                height: 40px;
                font-stretch: condensed;
                border-radius: 3px; 
                border: none;
                width: 100%;
                padding-left: 5px;
                &:focus{
                    outline: none;
                }
                font-weight: bold;
            }
        }
       
        .btn-booking{
            padding: 5px;
            width: 100%;
            color:#fff;  
            background: #1b1b1b;
            cursor: pointer;
             
            .booking-text{
                font-size: 20px;
                line-height: 40px;
                font-weight: bold;
                img{
                    width: 25px;
                    height: 25px;
                    vertical-align: middle;
                }
            }
            .cancle{
                font-size: 17px;
                line-height: 20px;
            }
    
            .img{
                width: 30px;
                height: 30px;
                margin-bottom: 8px;
                margin-left: 10px;
            }
        }
    
        .btn-history{
            padding: 10px 5px;
            width: 33%;
            color: #333;  
            background: #eee;
            margin-left: 2%;
            font-weight: 700;
            font-size: 19px;
            cursor: pointer;
        }
    }
    .sologan{
        text-align: center;
        font-size: 25px;
        color: #fff;
    }
  }


@media screen and (max-width: 540px){
    .input-sologan{
        position: inherit;
        background: #333;
        padding: 10px 10px 0 10px;
        .input-phone{
            .input-text{
                input{
                    font-size: 5vw;
                    height: 40px;;
                }
            }
            
            .btn-booking{
                .booking-text{
                    font-size: 4vw;
                    line-height: 32px;
                }
                .cancle{
                    font-size: 4vw;
                    font-stretch: initial;
                }
            }
    
            .btn-history{
                font-size: 3.5vw;
            }
        }
    }
   
}

@media screen and (max-width: 320px){
    .input-sologan{
        .input-phone{
            .input-text{
                .icon{
                    img{
                        width: 35px;
                        height: 35px;
                    }
                }
               
                input{
                    font-size: 20px;
                    height: 35px;;
                }
            }
        }
    }
   
}