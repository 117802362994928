
.home{
    // position: relative;
    .header{
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        font-stretch: condensed;
    }
    .home_slide{
        position: relative;
        .swiper-button-next,.swiper-button-prev{
            background-image: none;
        }
        .swiper-pagination-bullet-active{
            background: #1b1b1b;
        }   
        .swiper-slide{
            img{
                width: 100%;
            }
        }
        
        .swiper-container{
            position: relative;
            .ic-next , .ic-prev{
                position: absolute;
                top: 50%;
                z-index: 1;
                cursor: pointer;
            }
            .ic-next{
                right: 20px;
            }
            .ic-prev{
                left: 20px;
            }
        }
        
    }
}

@media screen and (max-width: 767px){
    .home{
        .header{
            font-size: 16px;
        }
    }
}