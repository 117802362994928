.modal-location{
    top: 3% !important;
    .ant-modal-close{
        top: 10px;
        right: 10px;
        .ant-modal-close-x{
            background: #fff;
            height: 35px;
            width: 35px;
            line-height: 38px;
        }
    }
    .ant-modal-header{
        
        background: #f00;
        padding: 16px 10px;
        .ant-modal-title{
            color: #fff;
            font-size: 18px;
        }
    }
    .ant-modal-content{
        .ant-modal-body{
            padding: 0;
            font-size: 20px;
            
            color: #2e2d2d;
            .hotline{
                text-align: center;
                margin-top: 12px;
                text-transform: uppercase;
            }
            .action{
                padding: 10px;
                display: flex;
                justify-content: space-between;
                div{
                    width: 50%;
                }
                .text{
                    line-height: 38px;
                }
                .image{
                    background-image: url('https://v3.30shine.org/data/images/btn-phone.png');
                    background-repeat: no-repeat;
                    width:50%;
                    background-size: contain;
                    height: 50px;
                    padding-left: 12%;
                    line-height: 45px;
                    a{
                        color: #fff;
                    }
                }
            }
        }
    }
    .dropdown-location{
        .list-data{
            max-height: 600px;
            // .phone{
            //     width: 45%;
            //     margin-left: 7%;
            //     .box{
            //         width: 80%;
            //     }
            // }
        }
    }
}


@media screen and (min-width: 768px) {
    .modal-location{
        .dropdown-location{
            .list-data{
                .phone{
                    width: 45%;
                    margin-left: 7%;
                    .box{
                        width: 80%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 350px) {
    .modal-location{
        .ant-modal-content{
            .ant-modal-body{
                font-size: 18px;
                .action{
                    .text{
                        line-height: 28px;
                    }
                    .image{
                        height: 31px;
                        line-height: 30px;  
                    }
                }
            }
           
        }
    }
}


@media screen and (max-width: 768px){
    .modal-location{
        .ant-modal-content{
            .ant-modal-body{
                .action{
                    .image{
                        line-height: 37px; 
                        font-size: 17px;
                        padding-left: 14%;
                    }
                }
            }
           
        }
        // max-width: 420px;
        .ant-modal-header{
            .ant-modal-title{
                font-size: 4.5vw;
            }
        }
    }
}


// @media screen and (min-width: 768px){
//     .modal-location{
//         max-width: 420px;
//     }
// }


// @media screen and (max-width: 414px){
//     .modal-location{
//         .dropdown-location{
//             .list-data{
//                 max-height: 420px;
//             }
//         }
//     }
// }


// @media screen and (max-width: 400px){
//     .modal-location{
//         .dropdown-location{
//             .list-data{
//                 max-height: 370px;
//             }
//         }
//     }
// }

