.shin-care {
	margin-top: 4px;
	background: #fff;
	padding-bottom: 20px;
	.content {
		display: flex;
		.ant-tabs.ant-tabs-card .ant-tabs-card-bar {
			.ant-tabs-nav-container,
			.ant-tabs-tab,
			.ant-tabs-tab-active {
				height: auto;
			}
		}
		.ant-tabs-content {
			min-height: 150px;
			background-color: #dfdfdf;
		}
		.ant-tabs-nav > div {
			display: flex;
		}
		.header {
			padding: 10px 10px 10px 6px;
			font-weight: bold;
			// font-size: 22px;
		}
		.content-left {
			width: 60%;
			.content-tab {
				border: 1px solid #ccc;
				.ant-tabs {
					font-size: 16px;
				}
				//    .ant-tabs-content{
				//        height: 201px;
				//    }
			}
			.item_tab {
				text-align: center;
				padding: 5px 7px;
				.circle {
					width: 100px;
					height: 100px;
					border-radius: 50%;
					border: 1px solid #333;
					font-size: 24px;
					line-height: 57px;
					color: #333;
					// padding: 18%;
					margin: auto;
				}
				.text-bottom {
					font-size: 14px;
					white-space: normal;
					line-height: 20px;
					margin-top: 10px;
					color: #151414;
					padding: 0 2px;
				}
			}
			.ant-tabs-nav-wrap {
				.ant-tabs-nav {
					width: 100%;
				}
				.ant-tabs-tab {
					width: 25%;
					padding: 10px 5px;
					margin: 0;
					border: none;
					border-radius: 0;
				}
				.ant-tabs-tab-active {
					background: #dfdfdf;
					.circle {
						background: #333;
						color: #fff;
					}
				}
			}
			.ant-tabs-ink-bar {
				background-color: #dfdfdf;
			}

			.ant-tabs-tab {
				margin: 0;
			}
			.header-tab {
				text-align: center;
				line-height: 28px;
				font-weight: bold;
				padding: 18%;
				.fix-bottom {
					line-height: 5px;
				}
			}
			.fix-top {
				padding: 0;
				padding-top: 21px;
				.text {
					font-size: 20px;
					font-stretch: condensed;
				}
			}
		}
		.ant-tabs-top-bar {
			margin: 0px;
		}
		.content-right {
			width: 38%;
			margin-left: 2%;
		}
	}
}

@media screen and (max-width: 768px) {
	.shin-care {
		padding-bottom: 5px;
		.content {
			display: block;
			.content-left {
				width: 100%;
				.content-tab {
					.ant-tabs {
						font-size: 3.8vw;
					}
					// .ant-tabs-content{
					//     height: 209px;
					// }
				}
				.item_tab {
					padding: 5px 0;

					.circle {
						width: 60px;
						height: 60px;
						font-size: 25px;
						//   padding: 4px 0 0 0;
					}
					.text-bottom {
						text-transform: none;
						font-size: 3.1vw;
					}
				}
				.ant-tabs-nav-wrap {
					.ant-tabs-tab {
						padding: 10px 0px;
					}
				}

				.header-tab {
					line-height: 24px;
					font-size: 16px;
					padding: 4px 0 0 0;
				}
				.fix-top {
					padding-top: 8px;
					.text {
						font-size: 12px;
					}
					.number {
						line-height: 17px;
					}
				}
			}
			.content-right {
				width: 100%;
				margin-left: 0;
			}
		}
	}
}
