.footer {
	background: rgb(27, 27, 27);
	font-size: 18px;
	.content {
		max-width: 1024px;
		margin: auto;
		padding: 10px;
		color: rgb(238, 237, 237);
		display: flex;
		justify-content: space-between;
		.left {
			width: 50%;
			.time {
				color: #fbd629;
				font-size: 19px;
				line-height: 35px;
			}
			a {
				color: #fff;
				text-decoration: underline;
				margin-bottom: 15px;
			}
			.phone {
				margin-top: 10px;
			}
			img {
				width: 200px;
			}
		}
		.right {
			width: 50%;
			text-align: right;
			.logo {
				display: flex;
				justify-content: flex-end;
				.icon1 {
					width: 205px;
				}
				.protected-by {
					margin-top: 10px;
				}
			}
			.visa-card {
				display: flex;
				justify-content: flex-end;
				margin: 10px 0;
			}
		}
	}
	.line-chat {
		position: fixed;
		bottom: 100px;
		right: 25px;
		box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
		border-radius: 30px;
		z-index: 90;
		img {
			width: 60px;
		}
	}
	.chat-custom {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 60px;
		height: 60px;
		position: fixed;
		bottom: 65px;
		right: 20px;
		border-radius: 50%;
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
		z-index: 90;
		cursor: pointer;
		background-color: #fabd03;
		font-size: 24px;
		img {
			width: 30px;
			height: 30px;
		}
		.icon-close {
			width: 20px;
			height: 20px;
		}
	}
	.show-btn-wrapper {
		width: 0;
		height: 0;
		position: fixed;
		bottom: 0;
		right: 0;
		z-index: 90;
		&.toggle {
			z-index: 92;
		}
		.chat-custom__button {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 55px;
			position: fixed;
			bottom: 60px;
			right: 50px;
			border-radius: 30px;
			z-index: 89;
			cursor: pointer;
			.text {
				width: 100%;
				display: flex;
				justify-content: flex-end;
				color: white;
				margin-right: 10px;
				font-family: 'BE';
				font-weight: 700;
				font-size: 14px;
				font-style: normal;
				line-height: 26px;
			}
			img {
				width: 48px;
				height: auto;
			}
		}
		.chat-custom__button-2 {
			bottom: -15px;
			right: 75px;
			border-radius: 30px;
			z-index: 90;
		}
	}
	.chat-custom-no-active {
		width: 100%;
		height: 100%;
		position: absolute;
		transform: scale(0);
		transform-origin: bottom right;
		transition: all 0.3s ease;
		bottom: 70px;
		right: 40px;
	}
	.chat-custom-active {
		width: 100%;
		height: 100%;
		position: absolute;
		transform: scale(1);
		transform-origin: bottom right;
		transition: all 0.3s ease;
		bottom: 70px;
		right: 40px;
	}
}

@media screen and (max-width: 767px) {
	.footer {
		font-size: 14px;
		.content {
			display: grid;
			.left,
			.right {
				width: 100%;
				.time {
					font-size: 14px;
				}
			}
			.left {
				img {
					width: 130px;
				}
			}
			.right {
				text-align: left;
				.logo {
					padding-left: 0;
					margin-bottom: 15px;
					justify-content: flex-start;
					.icon1 {
						width: 150px;
					}
					.protected-by {
						margin-top: 0;
					}
				}
				.visa-card {
					justify-content: start;
				}
			}
		}
	}
}
